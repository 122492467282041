/* eslint-disable no-console */
import React from 'react';
import ShopifyClient from 'shopify-buy';
import { get, set, del } from 'src/util/storage';
import { withDrawerContext, TABS } from 'src/state/DrawerState';

const initialState = {
  cart: {},
  checkoutId: null,
  products: [],
  addLineItemToCart: () => { },
  updateLineItemInCart: () => { },
  removeLineItemFromCart: () => { },
  addDiscount: () => { },
  removeDiscount: () => { }
}

const ShopifyContext = React.createContext(initialState);

class ShopifyState extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.shopifyClient = null;
  }

  componentDidMount() {
    this.shopifyClient = ShopifyClient
      .buildClient({
        domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
        storefrontAccessToken: process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
      });
    // this.fetchAllProducts()
    // this.getCheckout()
  }

  componentDidUpdate() {
    // const { checkoutId } = this.state;
    // if (!checkoutId) this.getCheckout()
  }

  setValue = (obj) => new Promise((resolve, reject) => {
    if (typeof obj !== 'object') reject('did not pass object')
    else this.setState(obj, resolve)
  })

  fetchAllProducts = () => {
    this.shopifyClient.product
      .fetchAll()
      .then((products) => this.setValue({ products }))
      .catch(e => console.log(e))
  }

  getCheckout = () => {
    const checkoutId = get('checkoutId');
    if (checkoutId) {
      this.fetchCheckout({ checkoutId })
    } else {
      this.createCheckout()
    }
  }

  createCheckout = () => {
    this.shopifyClient.checkout
      .create()
      .then(cart => {
        set('checkoutId', cart.id)
        this.setValue({ checkoutId: cart.id, cart })
      })
      .catch(e => console.log(e))
  }

  fetchCheckout = ({ checkoutId }) => {
    this.shopifyClient.checkout
      .fetch(checkoutId)
      .then(cart => {
        if (cart.completedAt) {
          // already completed checkout
          del('checkoutId');
          this.createCheckout();
        } else {
          this.setValue({ checkoutId, cart })
        }
      })
  }

  addLineItemToCart = ({ checkoutId, variantId, quantity = 1 }) => {
    const items = [{ variantId, quantity }]
    this.shopifyClient.checkout
      .addLineItems(checkoutId, items)
      .then(cart => this.setValue({ cart }))
      // eslint-disable-next-line react/destructuring-assignment
      .then(() => this.props.drawerContext.toggleDrawer(TABS.CART))
      .catch(e => console.log(e))

  }

  removeLineItemFromCart = ({ checkoutId, id }) => {
    const items = [id]
    this.shopifyClient.checkout
      .removeLineItems(checkoutId, items)
      .then(cart => this.setValue({ cart }))
      .catch(e => console.log(e))

  }

  updateLineItemInCart = ({ checkoutId, id, quantity }) => {
    const items = [{ id, quantity }]
    this.shopifyClient.checkout
      .updateLineItems(checkoutId, items)
      .then(cart => this.setValue({ cart }))
      .catch(e => console.log(e))
  }

  addDiscount = ({ checkoutId, discountCode }) => {
    this.shopifyClient.checkout
      .addDiscount(checkoutId, discountCode)
      .then(cart => this.setValue({ cart }))
      .catch(e => console.log(e))
  }

  removeDiscount = ({ checkoutId }) => {
    this.shopifyClient.checkout
      .removeDiscount(checkoutId)
      .then(cart => this.setValue({ cart }))
      .catch(e => console.log(e))
  }

  render() {
    const { children } = this.props;
    return (
      <ShopifyContext.Provider
        value={{
          ...this.state,
          addLineItemToCart: this.addLineItemToCart,
          updateLineItemInCart: this.updateLineItemInCart,
          removeLineItemFromCart: this.removeLineItemFromCart,
          addDiscount: this.addDiscount,
          removeDiscount: this.removeDiscount,
        }}
      >
        {children}
      </ShopifyContext.Provider>
    )
  }
}

export default withDrawerContext(ShopifyState);

export const withShopifyContext = (Component) => (props) => (
  <ShopifyContext.Consumer>
    {(context) => (
      <Component
        {...props}
        shopifyContext={context}
      />
    )}
  </ShopifyContext.Consumer>
)