import React from 'react';

const initialState = {
  isOpen: false,
  tab: null,
  toggleDrawer: () => { },
}

export const TABS = {
  NAVIGATION: 'NAVIGATION',
  SUBSCRIBE: 'SUBSCRIBE',
  FILTERS: 'FILTERS',
  CART: 'CART'
}

export const DrawerContext = React.createContext(initialState);

class DrawerState extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (e) => {
    if (e && e.keyCode === 27) { // escape key maps to keycode `27`
      this.toggleDrawer()
    }
  }


  toggleDrawer = (newTab) => {
    const { isOpen, tab } = this.state;
    if (newTab && !(isOpen && tab)) this.setState({ tab: newTab, isOpen: true });
    else this.setState({ isOpen: false })
  }

  render() {
    const { children } = this.props;
    return (
      <DrawerContext.Provider
        value={{
          ...this.state,
          toggleDrawer: this.toggleDrawer,
        }}
      >
        {children}
      </DrawerContext.Provider>
    )
  }
}

export default DrawerState;

export const withDrawerContext = (Component) => (props) => (
  <DrawerContext.Consumer>
    {(context) => <Component {...props} drawerContext={context} />}
  </DrawerContext.Consumer>
)