/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import Header from 'src/components/Header'
import ATF from 'src/components/ATF'
import ComponentRenderer from 'src/components/ComponentRenderer'
import ReactGA from 'react-ga'
import { withShopifyContext } from 'src/state/ShopifyState'
import { withContentfulContext, CONTENT_FILTERS } from 'src/state/ContentfulState'
import SEO from 'src/components/SEO'
import sortContent from 'src/util/sortContent'

class CategoryPage extends Component {

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    const { contentfulContext: { cSiteSettings, cEntries }, match, shopifyContext: { products } } = this.props

    const { params } = match;

    const splashImage = cSiteSettings.aboveTheFoldImage && `https:${cSiteSettings.aboveTheFoldImage.fields.file.url}`
    const mobileSplashImage = cSiteSettings.mobileAboveTheFoldImage
      ? `https:${cSiteSettings.mobileAboveTheFoldImage.fields.file.url}`
      : splashImage

    let filteredContent = []

    console.log(cEntries)
    console.log(CONTENT_FILTERS)

    if (params.type === 'store') {
      // store category
      filteredContent = products
    } else if (params.type && CONTENT_FILTERS[params.type]){
      filteredContent = cEntries.filter(item => (
        CONTENT_FILTERS[params.type].find(x => (
          item.sys.contentType.sys.id.includes(x)
        ))
      ))
    }

    filteredContent = sortContent(filteredContent)

    return (
      <>
        <SEO />
        <div>
          <Header
            filter={params && params.type}
            hasAtf
            bgImage={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            bgImageSmall={{
              fluid: {
                aspectRatio: 2,
                src: mobileSplashImage
              }
            }}
          />
          <ATF
            fullHeight={splashImage}
            headline={cSiteSettings.siteTitle || 'love reprise'}
            headlineSize="h1"
            location={params.type}
            small={{
              fluid: {
                aspectRatio: 2,
                src: mobileSplashImage
              }
            }}
            medium={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            large={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            nextTheme="bgColor"
            filters
          />
          {filteredContent.map((item, index) => {
            const firstItem = index === 0
            const lastItem = filteredContent.length === index + 1
            if (!item) return ''
            return (
              <ComponentRenderer
                key={`${item.sys ? item.sys.id : item.id}_${index}`}
                item={item}
                index={index}
                firstItem={firstItem}
                lastItem={lastItem}
              />
            )
          })}
        </div>
      </>
    );
  }
}

export default withContentfulContext(withShopifyContext(CategoryPage));