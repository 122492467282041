import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image/withIEPolyfill'
import { mq } from 'src/styles'

const StyledImage = styled(Img)`
	${ ({ fluid, type, rotateImage }) => `
		> div {
			${ fluid && fluid[1] ? `
				${ mq.largeAndBelow} {
					padding-bottom: ${ 100.0 / fluid[1].aspectRatio}% !important;
				}
			` : ``}
			${ fluid && fluid[2] ? `
				${ mq.mediumAndBelow} {
					padding-bottom: ${ 100.0 / fluid[2].aspectRatio}% !important;
				}
        ` : ``}
      }
      ${rotateImage ? `img { transform: rotate(90deg); }` : ''}
	` }
`

const ResponsiveImage = ({ image, small, medium, large, className, type }) => {
  if (small || medium || large || image) {
    let source = null
    if (image) {
      source = {
        ...image.fluid,
        src: `${image.fluid.src}?fm=jpg`,
      }
    } else {
      source = [
        {
          ...large.fluid,
          src: `${large.fluid.src}?fm=jpg`,
          media: `(min-width: ${mq.largeBreakpoint + 1}px)`,
        },
        {
          ...medium.fluid,
          src: `${medium.fluid.src}?fm=jpg`,
          media: `(min-width: ${mq.mediumBreakpoint + 1}px)`,
        },
        {
          ...small.fluid,
          src: `${small.fluid.src}?fm=jpg`,
          media: `(min-width: 1px)`,
        }
      ]
    }
    return (
      <StyledImage
        className={className}
        fluid={source}
        placeholderStyle={{ display: 'none' }}
        durationFadeIn={1000}
      />
    )
  }
    return false

}

const Image = ({ useMultipleImages, objectFit, style, small, medium, large, image, className }) => (
  <ResponsiveImage
    objectFit={objectFit}
    image={image}
    small={small}
    medium={medium}
    large={large}
    className={className}
  />
)

export {
  ResponsiveImage,
  Image as default
}
