import React, { Component } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Image from 'src/components/GatsbyImage'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ConditionalRender from 'src/components/ConditionalRender'
import TextLockup from 'src/components/TextLockup'
import Link from 'src/components/Link'
import ThemeSelector from 'src/components/ThemeSelector'
import Button from 'src/components/Button'
import withSizes from 'react-sizes'
import { colors, animations, util, typography, mq } from 'src/styles'
import MobileDetect from 'mobile-detect'
import Video from 'src/components/Video'
import Parallax from 'src/components/Parallax'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import { withDrawerContext, TABS } from 'src/state/DrawerState';

const Wrapper = styled(ThemeSelector)`
	position: relative;
	${ ({ media }) => media && `
		background: ${ colors.black};
		color: ${ colors.bgColor};
	` }
`

const AlignmentContainer = styled.div`
	display: flex;
	align-items: ${ ({ vAlignment }) => vAlignment};
	min-height: ${ ({ fullHeight, winHeight }) => fullHeight ? `calc(${winHeight}px - 80px)` : '70vh'};
	${ ({ fullHeight, winHeight }) => fullHeight ? `
		min-height: ${ fullHeight ? `calc(${winHeight}px - 80px)` : '70vh'};
		padding-top: 6vw;
		padding-bottom: 8vw;
		${ mq.mediumAndBelow} {
			padding-bottom: 12vw;
		}
	` : `
		min-height: ${ fullHeight ? `calc(${winHeight}px - 80px)` : '70vh'};
		padding-top: 80px;
		padding-bottom: 95px;
	` }
`

const Content = styled.div`
	width: 100%;
	text-align: ${ ({ hAlignment }) => hAlignment};
`

const Block = styled.div`
	display: block;
	width: 100%;
	position: relative;

	${ ({ background }) => background && `
		position: absolute;
		height: 100%;
		overflow: hidden;
		z-index: 1;
		bottom: ${ ({ fullHeight }) => fullHeight ? `60px` : `0`};
	` }

	${ ({ content, fullHeight }) => content && `
		z-index: 3;
	` }
`

const BgImage = styled(Image)`
	height: 100%;
	width: 100%;
	position: relative;
	img {
		object-fit: cover;
		object-position: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`

const EntryFilters = styled(Parallax)`
	color: ${ ({ color }) => colors[color]};
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	right: 0;
	${ util.responsiveStyles('padding-bottom', 30, 20, 18, 18)}
	display: flex;
	justify-content: space-around;
	a {
		display: block;
		font-weight: 600;
	}
`

const LeadDownPiece = styled.div`
	width: 100%;
	height: 100%;
	background: currentColor;
	${ ({ side }) => side === 'right' ? `
		border-radius: 44px 0 0 0;
		margin-left: -1px;
	` : `
		border-radius: 0 44px 0 0;
		margin-right: -1px;
	` }
`

const Overlay = styled.div`
	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	opacity: .4;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 30%;
	max-height: 300px;
	min-height: 100px;
	z-index: 3;
`

const ImageOverlay = styled.div`
	background: #000;
	opacity: ${ ({ overlay }) => overlay};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
`

const VideoContainer = styled.div`
	transition: opacity ${ animations.mediumSpeed} ease-in-out;
	position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
	z-index: -1;
	pointer-events: none;

	> div > div span {
		display: none;
	}

`

const VideoStyled = styled(Video)`
	z-index: -10;
	.rh5v-DefaultPlayer_controls {
    position: absolute;
		bottom: 0;
		display: none;
		visibility: hidden;
    right: 0;
    left: 0;
    height: 0;
	}
`

const FilterLink = styled(Link)`
	${ ({ active }) => active ? `
		&:after {
			opacity: 1;
		}
	` : ``}
`

const AtfFooter = styled(Parallax)`
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	border-top: 1px solid ${ colors.bgColor};
	button {
		flex-grow: 1;
		&:nth-child(2) {
			border-left: 1px solid ${ colors.bgColor};
		}
	}
`

const FooterButton = styled(Button)`
	border: none;
	font-size: 18px !important;
	color: ${ colors.bgColor};
`

class ATF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoFailed: false,
      scrolled: false,
      scrollDistance: 0
    }
  }


  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }

  shouldComponentUpdate(prevProps, prevState) {
    const md = new MobileDetect(window.navigator.userAgent)
    if (md.os() === 'iOS' && prevProps.winHeight !== this.props.winHeight) {
      return false
    }
    return true
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    this.setState({ scrollDistance: scrollTop })
    if (scrollTop > 10) {
      if (!this.state.scrolled) {
        this.setState({ scrolled: true })
      }
    } else if (this.state.scrolled) {
      this.setState({ scrolled: false })
    }
  }

  playVideo = () => {
    try {
      document.getElementsByTagName('video')[0].play().then(() => {
        this.setState({ videoFailed: false })
      })
    } catch { }
  }

  render() {
    const {
      headline,
      headlineSize,
      text,
      textSize,
      image,
      small,
      medium,
      large,
      video,
      textAlignment,
      hAlignment,
      vAlignment,
      fullHeight,
      buttons,
      winHeight,
      eyebrow,
      index,
      theme,
      nextTheme,
      overlay,
      filters,
      className,
      setMenuOpen,
      location,
      drawerContext: {
        toggleDrawer,
      }
    } = this.props

    const {
      videoFailed
    } = this.state

    const vAlignOptions = {
      bottom: 'flex-end',
      top: 'flex-start',
      baseline: 'baseline',
      center: 'center'
    }

    const hAlignmentGrid = {
      center: '1 [12] 1',
      left: '1 [6] 7',
      right: '7 [6] 1'
    }

    const verticalAligment = vAlignOptions[vAlignment]
    return (
      <Wrapper setTheme={theme} media={image || video} fullHeight={fullHeight} className={className}>
        <Block background winHeight={winHeight} fullHeight={fullHeight}>
          <ConditionalRender condition={video}>
            <VideoContainer>
              <VideoStyled
                cover
                loop
                playing
                muted
                controls={['PlayPause']}
                url={video && video.file.url}
              />
            </VideoContainer>
          </ConditionalRender>
          {!video && image || small ? (
            <BgImage
              image={image}
              small={small}
              medium={medium}
              large={large}
            />
          ) : false}
          {index === 0 && (video || image) ? <Overlay /> : false}
          {overlay ? <ImageOverlay overlay={overlay} /> : false}
        </Block>
        <Block content="true" winHeight={winHeight} fullHeight={fullHeight}>
          <AlignmentContainer vAlignment={verticalAligment} winHeight={winHeight} fullHeight={fullHeight}>
            <Content hAlignment={hAlignment}>
              <Grid
                small="1 [12] 1"
                medium="1 [12] 1"
                large={hAlignmentGrid[hAlignment]}
              >
                <Parallax opacityEnd={-.5} opacityStart={4}>
                  <TextLockup
                    theme="bgColor"
                    eyebrow={eyebrow}
                    alignment={textAlignment}
                    headlineSize={headlineSize}
                    headlineElement="h1"
                    headline={headline}
                    text={text}
                    textSize={textSize}
                    buttons={buttons}
                  />
                </Parallax>
              </Grid>
            </Content>
          </AlignmentContainer>
        </Block>

        {filters && (
          <ResponsiveComponent
            small={(
              <AtfFooter opacityEnd={-.5} opacityStart={1.5}>
                <FooterButton size="large" setTheme="hollow" onClick={() => toggleDrawer(TABS.FILTERS)}>
                  {!location && 'Filter'}
                  {location === 'photos' && 'Photos'}
                  {location === 'videos' && 'Videos'}
                  {/* {location === 'store' && 'Store'} */}
                  {location === 'audio' && 'Audio'}
                </FooterButton>
                {/* <FooterButton size="large" setTheme="hollow">Cart</FooterButton> */}
              </AtfFooter>
            )}
            medium={(
              <EntryFilters opacityEnd={-.5} opacityStart={1.5}>
                <FilterLink type="textLink" to="/" active={!location}>All</FilterLink>
                <FilterLink type="textLink" to="/photos" active={location === 'photos'}>Photos</FilterLink>
                <FilterLink type="textLink" to="/videos" active={location === 'videos'}>Videos</FilterLink>
                {/*
                <FilterLink type="textLink" to="/store" active={location === 'store'}>Store</FilterLink>
                */}
                <FilterLink type="textLink" to="/audio" active={location === 'audio'}>Audio</FilterLink>
              </EntryFilters>
            )}
          />
        )}

      </Wrapper>
    )
  }
}

ATF.defaultProps = {
  textAlignment: 'center',
  hAlignment: 'center',
  vAlignment: 'center',
  headlineSize: 'h1',
  textSize: 'body',
  theme: 'black',
  prevTheme: false,
  nextTheme: false,
  overlay: 0,
  filters: true
}

const sizesToProps = ({ width, height }) => ({
  winWidth: width,
  winHeight: height
})

export default withDrawerContext(withSizes(sizesToProps)(ATF))
