/* eslint-disable no-console */

export const set = (key, value) => {
  if (!localStorage) {
    console.warn('localStorage not supported');
  }

  if (!value) {
    return;
  }

  if (localStorage && localStorage.getItem(key)) {
    console.warn(`tried to set a value, but a ${key} is already set, overwriting`);
  }
  localStorage.setItem(key, value);
};

export const get = (key) => {
  if (!localStorage) {
    console.warn('localStorage not supported');
  }
  if (localStorage && !localStorage.getItem(key)) {
    console.warn(`tried to get a value, but no value is set for ${key}`);
  }
  return localStorage.getItem(key);
};

export const del = (key) => {
  if (!localStorage) {
    console.warn('localStorage not supported');
  }
  localStorage.removeItem(key);
};
