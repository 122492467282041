import React from 'react'
import BlockAudio from 'src/components/BlockAudio'
import BlockVideo from 'src/components/BlockVideo'
import BlockInstagram from 'src/components/BlockInstagram'
import BlockImage from 'src/components/BlockImage'
import BlockProduct from 'src/components/BlockProduct'

const componentMap = {
	contentItemMusic: BlockAudio,
	contentItemInstagramEmbed: BlockInstagram,
	contentItemImage: BlockImage,
  contentItemVideo: BlockVideo,
  product: BlockProduct,
}

const ComponentRenderer = ({ item, index }) => {
	const componentType = item.sys ? item.sys.contentType.sys.id : 'product'
	const Component = componentMap[componentType]

	return Component ? (
  <Component item={item} index={index} />
	) : ''
}

export default ComponentRenderer;