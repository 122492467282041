/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styled from '@emotion/styled';
import { colors, animations, mq, util, typography } from 'src/styles'
import Grid from 'src/components/Grid';
import { withShopifyContext } from 'src/state/ShopifyState';


const TextWrapper = styled.div`
  max-width: 100%;
  ${typography.bodySmall}
  display: flex;
  flex-direction: column;
  >* {
    padding-bottom: 8px;
  }
`;

const GridStyled = styled(Grid)`
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 10px;
`

const Title = styled.div`
  font-weight: 600;
`


const UnstyledButton = styled.button`
  font-size: inherit;
  background: inherit;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: left;
`

const SmallCapsButton = styled(UnstyledButton)`
  ${typography.smallCaps}
`
const GridInnerStyled = styled(Grid)`
  padding: 0 10px;
`


const Cart = ({
  shopifyContext: {
    checkoutId,
    cart,
    updateLineItemInCart,
    removeLineItemFromCart,
  },
}) => {
  if (!(cart && cart.lineItems && (cart.lineItems.length > 0))) return 'Your shopping bag is empty.'
  return cart.lineItems.map(item => {
    const { id, title, quantity, variant = {} } = item
    const {
      title: variantTitle,
      image,
      price,
      priceV2: { currencyCode }
    } = (variant || item);
    const priceString = currencyCode === 'USD' ? `$${price}` : `${price} ${currencyCode}`
    return (
      <GridStyled
        key={id}
        vAlign="center"
        small="[8] [18]" // 26 units to work with
        medium="[4] [14]" // 18 units to work with
        large="[4] [12]" // 14 units to work with
        extraLarge="[2] [6]" // 8 units to deal with
        colGap="0"
        rowGap="0"
      >
        <div>
          <img src={image.src} alt={image.altText || 'product variant photo'} />
        </div>
        <div>
          <GridInnerStyled
            small="[13] 1 [4]" // 18 units to work with
            medium="[8] 1 [3]" // 14 units to work with
            large="[5] 1 [2]" // 12 units to work with
            extraLarge="[4] 1 [1]" // 6 units to work with
            rowGap="0"
            colGap="0"
            vAlign="top"
          >
            <TextWrapper>
              <Title>{title}</Title>
              <small>{variantTitle}</small>
              <small>
                <UnstyledButton
                  onClick={() => updateLineItemInCart({ checkoutId, id, quantity: quantity - 1 })}
                >
                  -&nbsp;
                </UnstyledButton>
                {quantity}
                <UnstyledButton
                  onClick={() => updateLineItemInCart({ checkoutId, id, quantity: quantity + 1 })}
                >
                  &nbsp;+
                </UnstyledButton>
              </small>
              <div>
                <SmallCapsButton
                  onClick={() => removeLineItemFromCart({ checkoutId, id })}
                  style={{ color: colors.darkGrey }}
                >
                  REMOVE
                </SmallCapsButton>
              </div>
            </TextWrapper>
            <TextWrapper>
              {priceString}
            </TextWrapper>
          </GridInnerStyled>
        </div>
      </GridStyled>
    )
  })
}

export default withShopifyContext(Cart);