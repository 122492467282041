import React from 'react'
import styled from '@emotion/styled'
import { colors, animations, typography } from 'src/styles'
import { MdArrowForward } from 'react-icons/md'

import { Link as RouterLink } from 'react-router-dom'

export const linkStyles = ({ type, setTheme, active }) => `
	font-size: inherit;
	text-decoration: none;
	cursor: pointer;
	${ type === 'textLink' || type === 'arrowLink' || type === 'capsLink' ? `
		position: relative;
		display: inline-block;
		line-height: 1.3em;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: 1px;
			width: calc(100% - 2px);
			height: 1px;
			background: ${ colors[setTheme]};
			opacity: ${ active ? `1` : `0`};
			transition: opacity ${ animations.mediumSpeed} ease-in-out, background ${animations.mediumSpeed} ease-in-out;
		}
		&:hover {
			&:after {
				opacity: 1;
			}
		}
	` : ``}
	${ type === 'capsLink' ? `
		${ typography.h6}
	` : ``}
`

const StyledLinkElement = styled.a`
	${ ({ type, setTheme, active }) => `
		${ linkStyles({ type, setTheme, active })}
	` }
`

const StyledLink = styled(RouterLink)`
	${ ({ type, setTheme, active }) => `
		${ linkStyles({ type, setTheme, active })}
	` }
`

const ArrowIcon = styled(MdArrowForward)`
	display: inline-block;
	vertical-align: middle;
	margin-left: .3em;
	margin-top: -4px;
`

const Link = ({
  to,
  external,
  target,
  onClick,
  children,
  className,
  type,
  setTheme,
  active
}) => {
  if (external) {
    return (
      <StyledLinkElement
        className={className}
        href={to}
        onClick={typeof onClick === 'function' ? onClick : null}
        type={type}
        setTheme={setTheme}
        target="_blank"
        rel="noopener noreferrer"
        active={active}
      >
        {children}
        {type === 'arrowLink' && (
          <ArrowIcon size={18} />
        )}
      </StyledLinkElement>
    )
  }
  return (
    <StyledLink
      className={className}
      to={to}
      onClick={onClick}
      type={type}
      setTheme={setTheme}
      active={active}
    >
      {children}
      {type === 'arrowLink' && (
        <ArrowIcon size={18} />
      )}
    </StyledLink>
  )
}

Link.defaultProps = {
  to: '#',
  external: false,
  target: '',
  type: 'none',
  setTheme: 'currentcolor',
  active: false
}

export default Link
