import React, { Component } from 'react'
import styled from '@emotion/styled'
import { FiTwitter, FiFacebook, FiMail } from 'react-icons/fi'
import { typography, util, colors, animations, mq } from 'src/styles'
import Button from 'src/components/Button'

const Wrapper = styled.div`
	position: relative;
	display: flex;
	/* z-index: 1; */
	text-align: center;
	width: 180px;
  ${mq.mediumAndBelow} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
	height: 50px;
	svg {
		* {
			fill: transparent !important;
			stroke-width: 1.75px;
		}
	}
	&:hover {
		.cover {
			display: none;
      padding: 0;
		}
		a {
			display: block;
      padding: 0;
		}
	}
`

const ShareCover = styled.div`
	${ typography.buttonStyle}
	border: 2px solid ${ colors.textColor};
	height: 100%;
	width: 100%;
	padding-bottom: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	background: ${ colors.bgColor};
	color: ${ colors.textColor};
`

const StyledButton = styled(Button)`
padding: 0;
min-width: 60px;
display: none;
flex-grow: 1;

${ ({ position }) => position === 'center' ? `
  border-left: none;
  border-right: none;
  min-width: 46px;
` : ``}
`


const ShareButton = ({ sharePath, title, className }) => {
  const shareLink = `${process.env.REACT_APP_SITE_URL}/${sharePath}`
  return (
    <Wrapper className={className}>
      <ShareCover className="cover">Share</ShareCover>
      <StyledButton
        external
        to={`https://twitter.com/intent/tweet?url=${shareLink}&text=${title}`}
        onClick="window.open(this.href, 'mywin', 'left=20,top=20,width=700,height=700,toolbar=1,resizable=0'); return false;"
        setTheme="hollow"
        icon={<FiTwitter size="20" />}
      />
      <StyledButton
        external
        to={`https://www.facebook.com/sharer.php?u=${shareLink}`}
        onClick="window.open(this.href, 'mywin', 'left=20,top=20,width=600,height=600,toolbar=1,resizable=0'); return false;"
        position="center"
        setTheme="hollow"
        icon={<FiFacebook size="20" />}
      />
      <StyledButton
        external
        to={`mailto:?subject=${title}=${title} from Love Reprise&body=Check out ${title} from Love Reprise. <br/> ${shareLink}`}
        onClick="window.open(this.href, 'mywin', 'left=20,top=20,width=600,height=600,toolbar=1,resizable=0'); return false;"
        setTheme="hollow"
        title="Share by Email"
        icon={<FiMail size="20" />}
      />
    </Wrapper>
  )
}

ShareButton.defaultProps = {
  title: 'loverepri.se'
}

export default ShareButton