import React from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import ShareButton from 'src/components/ShareButton'
import ContentfulRichText from 'src/components/ContentfulRichText'
import * as mq from 'src/styles/mediaQueries'
import { typography, util } from 'src/styles'

const Wrapper = styled(Section)`
	width: 100%;
	max-width: 100vw;
	text-align: center;
`

const Container = styled.div`
	margin: 0 auto;
	max-width: 800px;
	text-align: center;
  ${mq.mediumAndBelow} {
    width: 100%;
  }
`

const BlockContent = styled.div`
	width: 100%;
	${ util.responsiveStyles('margin-bottom', 30, 24, 18, 14)}
`

const ButtonsContainer = styled.div`
  ${ ({ dropdown }) => !dropdown && util.responsiveStyles('margin-top', 30, 24, 18, 14)}
  max-width: 400px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ${mq.mediumAndBelow} {
    flex-direction: ${({collapseToColumn}) => collapseToColumn ? 'column' : 'row'};
  }
  >* {
    margin: 10px;
    ${mq.mediumAndBelow} {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`

const Title = styled.h3`
	${ typography.body}
	margin-bottom: 0;
	margin-top: 0;
`

const Block = ({ index, item, noTitle, priceString, nextTheme, prevTheme, children, sharePath, extraButtons, dropdown }) => {
  const { title, description, handle } = item.fields || item
  const itemType = item.sys ? item.sys.contentType.sys.id : 'product';
  return (
    <Wrapper
      nextTheme={nextTheme}
      prevTheme={prevTheme}
    >
      <Grid
        small={itemType === 'contentItemVideo' ? "[1]" : "1 [12] 1"}
        medium="1 [12] 1"
        large="1 [12] 1"
      >
        <div>
          <Container>
            <BlockContent>
              {children}
            </BlockContent>
            {(title && !noTitle) && (<Title>{title}</Title>)}
            {(description && (description.json || description.content)) && (<ContentfulRichText richText={description} />)}
            {(description && typeof description === 'string') && <div>{description}</div>}
            {priceString && <div style={{ paddingTop: 10 }}>{priceString}</div>}


              <ButtonsContainer>
                {dropdown}
              </ButtonsContainer>
              <ButtonsContainer collapseToColumn dropdown={dropdown}>
                {extraButtons}
                <Grid
                  small={itemType === 'contentItemVideo' ? "1 [12] 1" : "[1]"}
                  medium="[1]"
                  large="[1]"
                >
                  <Container>
                    <ShareButton sharePath={sharePath || handle} title={title} />
                  </Container>
                </Grid>
              </ButtonsContainer>
          </Container>
        </div>
      </Grid>
    </Wrapper>
  )
}

export default Block


