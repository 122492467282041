/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { rgba } from 'polished'

import Section from 'src/components/Section'
import Image from 'src/components/GatsbyImage'
import Button from 'src/components/Button'
import Ternary from 'src/components/Ternary'

import { colors, mediaQueries as mq, animations, util } from 'src/styles'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

const StyledSlider = styled(Slider)`
* {transition: height ${ animations.mediumSpeed} ease-in-out;}
	.slick-slide > div {
		display: flex;
		justify-content: center;
	}
`

const SlideshowWrapper = styled.div`
	max-width: 100%;
	overflow: hidden;
`

const NextButton = styled(Button)`
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 102%;
	margin: 0;
	border: none;
	color: ${ rgba(colors.textColor, .35)};
	${ mq.largeAndBelow} {
		right: 20px;
		left: auto;
		background: ${ rgba(colors.white, .1)};
	}
`
const PrevButton = styled(Button)`
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 102%;
	margin: 0;
	border: none;
	color: ${ rgba(colors.textColor, .35)};
	${ mq.largeAndBelow} {
		left: 20px;
		right: auto;
		background: ${ rgba(colors.white, .1)};
	}
`

const SlideImageContentful = styled(Image)`
	max-height: 80vh;
	object-fit: contain;
	img {
		object-fit: contain !important;
	}
`

const SlideImageShopify = styled.img`
	max-height: 80vh;
	object-fit: contain;
`

class Slideshow extends Component {
  goToNextSlide = () => {
    if (this.slideshow) {
      this.slideshow.slickNext()
    }
  }

  goToPrevSlide = () => {
    if (this.slideshow) {
      this.slideshow.slickPrev()
    }
  }

  render() {
    const {
      items,
      headline,
      text,
      theme,
      prevTheme,
      nextTheme,
      eyebrow,
      buttons,
      clickthroughLink
    } = this.props

    const slideshowSettings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 750,
      autoplay: false,
      autoplaySpeed: 4500,
      pauseOnHover: true,
      centerPadding: 50,
      adaptiveHeight: true
    }

    return (
      <div style={{ position: 'relative' }}>
        <SlideshowWrapper>
          <Ternary
            condition={clickthroughLink}
            trueComponent={(x) => <a {...x} href={clickthroughLink} target="_blank" rel="noopener noreferrer" />}
            falseComponent={(x) => <div {...x} />}
          >
            <Slider
              ref={c => (this.slideshow = c)}
              {...slideshowSettings}
              adaptiveHeight={!!(items && (items.length > 1))}
            >
              {items && items.map((item, index) => (
              item.fields
                ? (
                  <SlideImageContentful
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.sys && item.sys.id}_${index}`}
                    image={{
                      fluid: {
                        aspectRatio: item.fields && item.fields.file.details.image.width / item.fields.file.details.image.height,
                        src: item.fields ? item.fields.file.url : item.src
                      }
                    }}
                  />
                )
                : (
                  <SlideImageShopify
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.id}_${index}`}
                    src={item.src}
                    alt={item.altTag || 'product photograph'}
                  />
                )
            ))}
            </Slider>
          </Ternary>
        </SlideshowWrapper>
        {(items.length > 1)
          && (
            <>
              <NextButton shape="circle" setTheme="bgColor" size="small" onClick={this.goToNextSlide}><MdKeyboardArrowRight size={32} /></NextButton>
              <PrevButton shape="circle" setTheme="bgColor" size="small" onClick={this.goToPrevSlide}><MdKeyboardArrowLeft size={32} /></PrevButton>
            </>
          )}
      </div>
    )
  }
}

export default Slideshow
