/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import Header from 'src/components/Header'
import ATF from 'src/components/ATF'
import ComponentRenderer from 'src/components/ComponentRenderer'
import ReactGA from 'react-ga'
import { withShopifyContext } from 'src/state/ShopifyState'
import { withContentfulContext } from 'src/state/ContentfulState'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import SEO from 'src/components/SEO'


class PostPage extends Component {

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    const { contentfulContext: { cSiteSettings, cEntries }, match, shopifyContext: { products } } = this.props

    const { params } = match;

    const splashImage = cSiteSettings.aboveTheFoldImage && `https:${cSiteSettings.aboveTheFoldImage.fields.file.url}`
    const mobileSplashImage = cSiteSettings.mobileAboveTheFoldImage
      ? `https:${cSiteSettings.mobileAboveTheFoldImage.fields.file.url}`
      : splashImage
      
      let filteredContent = []
      let feedContent = []

      if (params.type === 'store') {
        filteredContent = products.filter(product => product.handle.includes(params.slug))
      } else {
        filteredContent = cEntries.filter(item => item.fields.sharePath.includes(params.slug))
        feedContent = cEntries.filter(item => !item.fields.sharePath.includes(params.slug))
      }
      filteredContent.sort((a, b) => {
        const entryA = a.sys ? a.sys.createdAt : a.createdAt;
        const entryB = b.sys ? b.sys.createdAt : b.createdAt;
        return entryA > entryB ? -1 : 1;
      })
      const currentItem = filteredContent[0] || {}
      const url = process.env.REACT_APP_SITE_URL + match.url
      const { title, description } = (currentItem.fields || currentItem)
      const pageTitle = `${cSiteSettings.siteTitle} | ${title}` || `Love Reprise | ${title}`
      const pageDescription = typeof description === 'string' ? description : documentToPlainTextString(description);

    return (
      <>
        <SEO 
          url={url}
          pageTitle={pageTitle}
          pageDescription={pageDescription}
        />
        <div>
          <Header
            filter={params && params.type}
            hasAtf={false}
            bgImage={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            bgImageSmall={{
              fluid: {
                aspectRatio: 2,
                src: mobileSplashImage
              }
            }}
          />
          {filteredContent.map((item, index) => {
            const firstItem = index === 0
            const lastItem = filteredContent.length === index + 1
            if (!item) return ''
            return (
              <ComponentRenderer
                key={`${item.sys ? item.sys.id : item.id}_${index}`}
                item={item}
                index={index}
                firstItem={firstItem}
                lastItem={lastItem}
              />
            )
          })}
          {feedContent.map((item, index) => {
            const firstItem = index === 0
            const lastItem = feedContent.length === index + 1
            if (!item) return ''
            return (
              <ComponentRenderer
                key={`${item.sys ? item.sys.id : item.id}_${index}`}
                item={item}
                index={index}
                firstItem={firstItem}
                lastItem={lastItem}
              />
            )
          })}
        </div>
      </>
    );
  }
}

export default withContentfulContext(withShopifyContext(PostPage));