import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled'
import Link, { linkStyles } from 'src/components/Link'
import Grid from 'src/components/Grid'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import { colors, animations, mq, util, typography } from 'src/styles'
import ATF from 'src/components/ATF'
import Drawer from 'src/components/Drawer'
import withSizes from 'react-sizes'
import { IoMdMenu, IoMdClose } from 'react-icons/io'
import { AiOutlineShopping } from 'react-icons/ai'
import { FiMail } from 'react-icons/fi'
import { withDrawerContext, TABS } from 'src/state/DrawerState';
import { withShopifyContext } from 'src/state/ShopifyState';
// const Logo = styled.div``

const navLinkStyle = ({ active, noMargin }) => `
	display: block;
  position: relative;
  ${ typography.bodyMedium }
	${ noMargin ? '' : util.responsiveStyles('margin-right', 60, 40, 32, 14)}
  font-weight: 600;
  &:focus:after, &:hover {
    transform: none;
  }
  ${ mq.mediumAndBelow } {
    ${ typography.body }
    font-weight: 700;
  }
	${ active && `
		&:after {
			transform: none;
		}
	` }
`

const NavLink = styled(Link)`
	${ ({ scrolled, active, noMargin }) => navLinkStyle({ active, noMargin })}
`

const NavButton = styled.button`
  ${({ type, setTheme, active, }) => linkStyles({ type, setTheme, active })}
	${ ({ scrolled, active, noMargin }) => navLinkStyle({ active, noMargin })}
  &:hover {

  }
  font-size: inherit;
  background: inherit;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  cursor: pointer;
`

const NavButtonAbsolute = styled(NavButton)`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0;
  >* {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    color: ${({ isOpen }) => isOpen ? colors.black : colors.white};
  }
`;

const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
	background: transparent;
	color: ${ colors.bgColor};
	transition: box-shadow ${ animations.mediumSpeed} ease-in-out;
	svg {
		display: inline-block;
		vertical-align: top;
		* {
			fill: currentcolor;
		}
	}
	${ ({ scrolled, hasAtf, isOpen }) => (scrolled && !isOpen) ? `
		// box-shadow: 0 2px 0px ${ colors.bgColor};
	` : `
		${ !hasAtf ? `
			color: ${ colors.bgColor};
		` : `
			color: ${ colors.bgColor};
		` }
	` }
`

const HeaderContainer = styled.div`
	position: relative;
	z-index: 2;
	transition: padding ${ animations.mediumSpeed} ease-in-out, max-height ${animations.mediumSpeed} ease-in-out;
	${ ({ scrolled, isOpen }) => (scrolled && !isOpen) ? `
		max-height: 50px;
		padding-top: 12px;
		padding-bottom: 12px;
	` : `
		max-height: 80px;
		${ util.responsiveStyles('padding-top', 30, 20, 18, 18)}
		${ util.responsiveStyles('padding-bottom', 30, 20, 18, 18)}
	` }
`


const LogoCol = styled.div`
	a {
		opacity: ${ ({ scrolled, hasAtf }) => !scrolled && hasAtf ? `0` : `1`};
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		${ typography.h6}
	}
	svg {
		display: block;
	}
`

const NavLinks = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
	justify-content: ${ ({ alignment }) => alignment === 'right' ? 'flex-end' : 'flex-start'};
	${ ({ mediumHide }) => mediumHide && `
		${ mq.mediumAndBelow} {
			display: none;
		}
	` }
	${ ({ smallHide }) => smallHide && `
		${ mq.mediumAndBelow} {
			display: none;
		}
	` }
`

const HeaderPlaceholder = styled.div`
	background: transparent;
	width: 100%;
	${ util.responsiveStyles('height', 140, 95, 80, 80)}
	display: none;
`

const BagIconWrap = styled.div`
	position: relative;
	mix-blend-mode: normal;
`

const BgATF = styled(ATF)`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	z-index: 0;
	opacity: ${ ({ visible, hasAtf }) => visible || !hasAtf ? 1 : 0};
`

const MailIcon = styled(FiMail)`
	* {
		fill: transparent !important;
		stroke-width: 1.75px;
	}
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      imgBg: false
    }
  }

  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { winHeight } = this.props;
    const { imgBg, scrolled } = this.state;
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    if (scrollTop >= winHeight - 50 - 80) {
      if (!imgBg) {
        this.setState({ imgBg: true })
      }
    } else if (imgBg) {
      this.setState({ imgBg: false })
    }
    if (scrollTop > 10) {
      if (!scrolled) {
        this.setState({ scrolled: true })
      }
    } else if (scrolled) {
      this.setState({ scrolled: false })
    }
  }

  render() {
    const {
      // location,
      filter,
      hasAtf,
      placeholder,
      bgImage,
      bgImageSmall,
      drawerContext: {
        toggleDrawer,
        isOpen,
      },
      shopifyContext: {
        cart,
      }
    } = this.props
    const { scrolled, imgBg } = this.state
    const numberOfItemsInCart = (cart && cart.lineItems && (cart.lineItems.length > 0))
      ? cart.lineItems.length
      : null
    return (
      <>
        <Wrapper scrolled={scrolled} isOpen={isOpen} hasAtf={hasAtf}>
          <HeaderContainer scrolled={scrolled} isOpen={isOpen} hasAtf={hasAtf}>
            <Grid
              small="1 [2] [10] 1"
              medium="1 [7] [19] 1"
              large="1 [7] [19] 1"
              vAlign="center"
            >
              <LogoCol scrolled={scrolled} hasAtf={hasAtf}>
                <ResponsiveComponent
                  small={(
                    <NavButtonAbsolute
                      setTheme='currentcolor'
                      isOpen={isOpen}
                      onClick={() => toggleDrawer(TABS.NAVIGATION)}
                    >
                      {isOpen
                        ? <IoMdClose size={24} />
                        : <IoMdMenu size={24} />}
                    </NavButtonAbsolute>
                  )}
                  medium={(
                    <Link to="/">
                      Love Reprise
                    </Link>
                  )}
                />
              </LogoCol>
              <div>
                <ResponsiveComponent
                  small={(
                    <NavLinks type="textLink" alignment="right">
                      <NavLink type="textLink" to="http://chetfaker.merchcentral.com" external target='_blank'>Shop CF</NavLink>
                      <NavLink type="textLink" to="http://nickmurphy.merchcentral.com" external target='_blank'>Shop NM</NavLink>
                      <NavButton
                        setTheme='currentcolor'
                        onClick={() => toggleDrawer(TABS.SUBSCRIBE)}
                        scrolled={scrolled}
                        hasAtf={hasAtf}
                        noMargin
                      >
                        Subscribe
                      </NavButton>
                      {/* <NavLink to="/contact"><MailIcon size={24} /></NavLink> */}
                      {/*
                      <NavLink
                        noMargin
                        scrolled={scrolled}
                        hasAtf={hasAtf}
                        onClick={() => toggleDrawer(TABS.CART)}
                      >
                        <BagIconWrap>
                          <AiOutlineShopping size={28} />
                          <div style={{
                            fontWeight: '900',
                            position: 'absolute',
                            bottom: '6px',
                            left: 0,
                            lineHeight: '1em',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '10px',
                          }}
                          >
                            {numberOfItemsInCart}
                          </div>
                        </BagIconWrap>
                      </NavLink>
                      */}
                    </NavLinks>
                  )}
                  medium={(
                    <NavLinks alignment="right">
                      <NavLink type="textLink" to="http://chetfaker.merchcentral.com" external target='_blank'>Shop CF</NavLink>
                      <NavLink type="textLink" to="http://nickmurphy.merchcentral.com" external target='_blank'>Shop NM</NavLink>
                      <NavButton
                        type="textLink"
                        setTheme='currentcolor'
                        onClick={() => toggleDrawer(TABS.SUBSCRIBE)}
                        scrolled={scrolled}
                        hasAtf={hasAtf}
                      >
                        Subscribe
                      </NavButton>
                      <NavLink noMargin type="textLink" to="/contact">Contact</NavLink>
                      {/*
                      <NavLink
                        type="textLink"
                        scrolled={scrolled}
                        hasAtf={hasAtf}
                        onClick={() => toggleDrawer(TABS.CART)}
                      >
                        Cart
                        {numberOfItemsInCart && ` (${numberOfItemsInCart})`}
                      </NavLink>
                      <NavButtonAbsolute
                        noMargin
                        setTheme='currentcolor'
                        isOpen={isOpen}
                        onClick={() => toggleDrawer(TABS.NAVIGATION)}
                      >
                        {isOpen
                          ? <IoMdClose size={24} />
                          : ''
                          // : <IoMdMenu size={24} />
                        }
                      </NavButtonAbsolute>
                      */}
                    </NavLinks>
                  )}
                />
              </div>
              <Drawer filter={filter} />
            </Grid>
          </HeaderContainer>
          <BgATF
            small={bgImageSmall}
            medium={bgImage}
            large={bgImage}
            fullHeight
            filters={false}
            visible={imgBg}
            hasAtf={hasAtf}
          />
        </Wrapper>

        {!hasAtf && placeholder && <HeaderPlaceholder />}

      </>
    )
  }
}

Header.defaultProps = {
  hasAtf: false,
  placeholder: true
}

const sizesToProps = ({ height }) => ({
  winHeight: height
})

export default withShopifyContext(withDrawerContext((withRouter(withSizes(sizesToProps)(Header)))))
