import React from 'react'
import Helmet from 'react-helmet'
import { withContentfulContext } from 'src/state/ContentfulState'

const SEO = ({
  contentfulContext: {
    cSiteSettings,
    url,
    pageTitle,
    pageDescription,
  }
}) => {
  const shareImage = (cSiteSettings && cSiteSettings.seoSocialShareImage) ? (`https:${cSiteSettings.seoSocialShareImage.fields.file.url}`) : ''


  const siteTitle = cSiteSettings ? cSiteSettings.siteTitle : 'love reprise'
  const fullTitle = pageTitle ? `${siteTitle} | ${pageTitle}` : siteTitle
  const pageDescriptionFallback = cSiteSettings ? cSiteSettings.seoDescription : ''
  const urlFallback = process.env.REACT_APP_SITE_URL
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{fullTitle}</title>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={pageDescription || pageDescriptionFallback} />
      <meta property="og:url" content={url || urlFallback} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={shareImage} />
      <meta property="og:image:secure_url" content={url || urlFallback} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="800" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={pageDescription || pageDescriptionFallback} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:image" content={shareImage} />
    </Helmet>
  )
}

export default withContentfulContext(SEO);