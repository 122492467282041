import React from 'react'
import styled from '@emotion/styled'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'

const RichTextWrapper = styled.div`
	*:first-child {
		margin-top: 0;
	}
	*:last-child {
		margin-bottom: 0;
	}
	white-space: pre-wrap;
	* {
		white-space: pre-wrap;
	}
`

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node && node.data && node.data.uri && node.data.uri.includes(process.env.REACT_APP_SITE_URL)) {
        return <a href={(node && node.data) && node.data.uri}>{children}</a>
      }
      return <a target="_blank" rel="noopener noreferrer" href={(node && node.data) && node.data.uri}>{children}</a>
    }
  },

}

const ContentfulRichText = ({ richText, className }) => (
  <RichTextWrapper className={className}>
    {documentToReactComponents(richText, options)}
  </RichTextWrapper>
)

export default ContentfulRichText
