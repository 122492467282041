import React from 'react'
import styled from '@emotion/styled'
import Block from 'src/components/Block'
import AudioPlayer from 'src/components/AudioPlayer'
import { util } from 'src/styles'
import * as mq from 'src/styles/mediaQueries'

const IframeEmbed = styled.iframe`
	width: 100%;
	height: 80vh;
	max-height: 700px;

`

const AudioPlayerWrap = styled.div`
	border: 2px solid;
	${ util.responsiveStyles('padding', 40, 35, 30, 25) }
  min-height: 480px;
  max-width: 480px;
  ${mq.mediumAndBelow} {
    max-width: 400px;
    min-height: 400px;
  }
  ${mq.smallAndBelow} {
    min-height: 75vw;
  }
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const BlockAudio = ({ index, item, nextTheme, prevTheme }) => {
	const { title, description, sharePath, appleMusicEmbed, spotifyEmbed, audioFile, coverImageForAudioFile } = item.fields
	const itemType = item.sys.contentType.sys.id
	const itemId = item.sys.id

	let audioType = ''

	if (audioFile) {
		audioType = 'file'
	} else if (appleMusicEmbed) {
		audioType = 'appleMusic'
	} else if (spotifyEmbed) {
		audioType = 'spotify'
	}

	return (
  <Block
    item={item}
    noTitle={audioType === 'file'}
    nextTheme={nextTheme}
    prevTheme={prevTheme}
    index={index}
    sharePath={`audio/${  sharePath}`}
		>
    {audioType === 'appleMusic' && (
    <IframeEmbed title={itemId} allow="autoplay *; encrypted-media *;" frameBorder="0" src={appleMusicEmbed} />
			)}
    {audioType === 'spotify' && (
    <iframe title={itemId} src={spotifyEmbed} width="100%" height="600" frameBorder="0" allowtransparency="true" allow="encrypted-media" />
			)}
    {audioType === 'file' && (
    <AudioPlayerWrap>
      <AudioPlayer title={item.fields.title} file={{ source_url: audioFile.fields.file.url }} coverImageForAudioFile={coverImageForAudioFile} />
    </AudioPlayerWrap>
			)}
  </Block>
	)
}

export default BlockAudio
