import React from 'react'
import styled from '@emotion/styled'
import { useScrollPercentage } from 'react-scroll-percentage'

const parallaxOffset = (num, outMin, outMax) => {
	// return (num - in_min) * (outMax - outMin) / (in_max - in_min) + outMin
	const offset = (num - 0) * (outMax - outMin) / (1 - 0) + outMin
	if (!offset) {
		return 0
	} 
		return offset
	
}

const ParallaxStyle = styled.div`
	display: inline-block;
	vertical-align: top;
	${ props => !props.disabled && `
		transform: 	translate3d(${ parallaxOffset(props.scroll, props.posXStart, props.posXEnd) }${ props.scrollUnit }, ${ parallaxOffset(props.scroll, props.posYStart, props.posYEnd) }${ props.scrollUnit }, 0)
								rotate(${ parallaxOffset(props.scroll, props.rotateStart, props.rotateEnd) }deg)
								scale(${ parallaxOffset(props.scroll, props.scaleStart, props.scaleEnd) });
		opacity: ${ parallaxOffset(props.scroll, props.opacityStart, props.opacityEnd) };
	` };
`

const Parallax = ({ 
		children,
		className,
		posYStart,
		posYEnd,
		posXStart,
		posXEnd,
		scrollUnit,
		rotateStart,
		rotateEnd,
		scaleStart,
		scaleEnd,
		opacityStart,
		opacityEnd
	}) => {
	const [ref, percentage] = useScrollPercentage({ threshold: 0 })
	return (
  <ParallaxStyle
    ref={ref}
    scroll={percentage.toPrecision(5) || 0}
    className={className}
    posYStart={posYStart}
    posYEnd={posYEnd}
    posXStart={posXStart}
    posXEnd={posXEnd}
    opacityStart={opacityStart}
    opacityEnd={opacityEnd}
    rotateStart={rotateStart}
    rotateEnd={rotateEnd}
    scaleStart={scaleStart}
    scaleEnd={scaleEnd}
    scrollUnit={scrollUnit}
		>
    {children}
  </ParallaxStyle>
	)
}

Parallax.defaultProps = {
	posYStart: 0,
	posYEnd: 0,
	posXStart: 0,
	posXEnd: 0,
	opacityStart: 1,
	opacityEnd: 1,
	rotateStart: 0,
	rotateEnd: 0,
	scaleStart: 1,
	scaleEnd: 1,
	scrollUnit: 'px'
}

export default Parallax
