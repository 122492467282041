/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import ReactGA from 'react-ga'
import styled from '@emotion/styled'
import Header from 'src/components/Header'
import SEO from 'src/components/SEO'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { withShopifyContext } from 'src/state/ShopifyState'
import { withContentfulContext } from 'src/state/ContentfulState'


const WrapperCopyFromBlock = styled(Section)`
	width: 100%;
	max-width: 100vw;
	text-align: center;
`

const Container = styled.div`
	margin: 0 auto;
	max-width: 800px;
`


export const TEXT_PAGE_TYPES = {
  contact: {
    cField: 'contact',
    title: 'Contact',
  },
  privacy: {
    cField: 'privacyPolicy',
    title: 'Privacy Policy',
  },
  terms: {
    cField: 'termsOfService',
    title: 'Terms of Service',
  },
}

class TextPage extends Component {

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    const { type, contentfulContext: { cSiteSettings }, match = {} } = this.props
    const { params } = match;

    const splashImage = cSiteSettings.aboveTheFoldImage && `https:${cSiteSettings.aboveTheFoldImage.fields.file.url}`
    const mobileSplashImage = cSiteSettings.mobileAboveTheFoldImage
      ? `https:${cSiteSettings.mobileAboveTheFoldImage.fields.file.url}`
      : splashImage
    const url = process.env.REACT_APP_SITE_URL + match.url
    const { title, cField } = type || {};
    const body = (cField && cSiteSettings) && cSiteSettings[cField]
    const pageTitle = title;
    const pageDescription = title
    console.log({url, pageTitle, pageDescription})

    return (
      <>
        <SEO
          url={url}
          pageTitle={pageTitle}
          pageDescription={pageDescription}
        />
        <div>
          <Header
            filter={params && params.type}
            hasAtf={false}
            bgImage={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            bgImageSmall={{
              fluid: {
                aspectRatio: 2,
                src: mobileSplashImage
              }
            }}
          />
          <WrapperCopyFromBlock>
            <Grid
              small="1 [12] 1"
              medium="1 [12] 1"
              large="1 [12] 1"
            >
              <div>
                <Container>
                  {body && <ContentfulRichText richText={body} />}
                </Container>
              </div>
            </Grid>
          </WrapperCopyFromBlock>
        </div>
      </>
    );
  }
}

export default withContentfulContext(withShopifyContext(TextPage));