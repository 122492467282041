import React from 'react'
import styled from '@emotion/styled'

import Button from 'src/components/Button'
import Grid from 'src/components/Grid'

import NMATP from 'src/assets/images/NMATP.png'
import TITR2 from 'src/assets/images/TITR2.png'

import { withContentfulContext } from 'src/state/ContentfulState'

import { typography, mq } from 'src/styles'


const Wrapper = styled.div`
`

const Image = styled.img`
	width: 100%;
`

const BackgroundImageContainer = styled.div`
	position: fixed;
	top: 0;
	left:0;
	width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
`

const BackgroundImage = styled(Image)`
	max-width: none;
  object-fit: cover;
	width: 120%;
  height: 120%;
  object-position: right;
  margin-left: -20%;
  margin-top: -10%;
  ${mq.mediumAndBelow} {
		width: 185%;
    height: 110%;
    margin-top: -10%;
    margin-left: -50%;
    object-position: right;
  }
  ${mq.smallAndBelow} {
		width: 185%;
    height: 110%;
    margin-top: -10%;
    margin-left: -20%;
    object-position: right;
  }
`

const ContentWrapper = styled.div`
	display: flex;
	height: 100vh;
	align-items: center;
	position: relative;
`

const Content = styled.div`
	text-align: right;
	min-height: 480px;
	display: flex;
	flex-direction: column;
  justify-content: space-between;
  p {
  	font-weight: bold;
  }
  ${mq.mediumAndBelow} {
  	height: 100vh;
  	padding: 3em 0;
 	}
`

const StyledButton = styled(Button)`
	border-radius: 25px;
	font-size: 13px;
	${typography.bodySmall};
	text-transform: uppercase;
	font-weight: bold;
	min-width: 240px;

`

const ContinueText = styled.p`
	font-weight: normal;
  ${mq.mediumAndBelow} {
	  color: white;
    border-color: white;
    &:hover {
    	border-color: white;
    }
 	}

`


const SplashPage = ({ ...props }) => {


	const { contentfulContext : {
		cSiteSettings : {
			changeSplashPage,
			albumReleaseDate,
			bgDesktop,
			bgMobile,
			spotifyLink,
			spotifyLinkText,
			appleMusicLink,
			appleMusicLinkText,
			tidalLink,
			tidalLinkText,
			deezerLink,
			deezerLinkText,
			preOrderLinkText,
			preOrderUsLink,
			footerText,
			footerLink,
		}
	}} = props

	const backgroundImageUrl = bgDesktop?.fields?.file?.url

	return (
		<Wrapper>
			<BackgroundImageContainer>
				<BackgroundImage src={backgroundImageUrl} alt="Nick Murphey" />
			</BackgroundImageContainer>
			<Grid
				small="2 [20] 2"
				medium="12 [11] 1"
				large="14 [9] 1"
			>
				<ContentWrapper>
				<Content>
						<div>
							<Image src={NMATP} alt="Nick Murphey & The Program" />
							<Image style={{marginTop: 8}} src={TITR2} alt="Take in The Roses" />
						</div>
						<div>
						<p className="small">
							THE NEW ALBUM
							<br />
							TAKE IN THE ROSES
							<br />
							{albumReleaseDate}
						</p>
						</div>
						<div>
						<p className="small">
							<StyledButton external={true} to={preOrderUsLink}>{preOrderLinkText}</StyledButton>
						</p>

						{spotifyLink && spotifyLinkText && (
							<p className="small">
								<StyledButton setTheme="bgColor" external={true} to={spotifyLink}>{spotifyLinkText}</StyledButton>
							</p>
						)}

						{appleMusicLink && appleMusicLinkText && (
							<p className="small">
								<StyledButton setTheme="bgColor" external={true} to={appleMusicLink}>{appleMusicLinkText}</StyledButton>
							</p>
						)}

						{deezerLink && deezerLinkText && (
							<p className="small">
								<StyledButton setTheme="bgColor" external={true} to={deezerLink}>{deezerLinkText}</StyledButton>
							</p>
						)}

						{tidalLink && tidalLinkText && (
							<p className="small">
								<StyledButton setTheme="bgColor" external={true} to={tidalLink}>{tidalLinkText}</StyledButton>
							</p>
						)}

						</div>
						<div>
						<ContinueText className="small">
							<a href={footerLink}>{footerText}</a>
						</ContinueText>
						</div>
				</Content>
				</ContentWrapper>
			</Grid>

		</Wrapper>
	)
}


export default withContentfulContext(SplashPage);
