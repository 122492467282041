import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Transition } from 'react-transition-group'
import { animations } from 'src/styles'
import ReactPlayer from 'react-player'

import { MdPlayArrow } from 'react-icons/md'

import Image from 'src/components/GatsbyImage'
import Button from 'src/components/Button'

import { mq } from 'src/styles'


const Wrapper = styled.div`
min-height: 360px;
  ${ ({ hasCoverImage }) => hasCoverImage && `
    position: relative;
  `}
  img, video {
    display: block;
    min-height: 360px;
    margin: 0;
  }
  ${ ({ coverImage }) => coverImage && `
    height: 100%;
  ` }
`

const CoverImageWrap = styled.div`
  position: relative;
  max-height: 450px;
  z-index: 50;
  overflow: hidden;
  margin: auto;
`

const CoverImage = styled(Image)`
  img {
    width: 100%;
  }
  >div {
    padding-bottom: 60%;
    overflow: hidden;
  }
`

const PlayButton = styled(Button)`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background ${ animations.mediumSpeed} ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, .1);
  }
`

const VideoWrapper = styled.div`
  min-height: 360px;
  position: relative;
  transition: opacity .5s ease-in-out;
  ${ ({ hasCoverImage }) => hasCoverImage && `
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  ` }
  ${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
    opacity: 1;
  ` : `
    opacity: 0;
  ` }
  ${ ({ coverImage }) => coverImage && `
    height: 100%;
  ` }
`

const PlayButtonWrapper = styled.div`
  position:absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 100;
	> button {
		border: none;
	}
`

const StyledVideo = styled(ReactPlayer)`
  z-index: 10;
  position: relative;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  width: 100% !important;
  height: auto !important;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  ${ ({ coverImage }) => coverImage && `
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: coverImage;
    }
  ` }
  ${ ({ url, coverImage }) => url.indexOf("youtube") > -1 || url.indexOf("vimeo") > -1 ? `
    padding-bottom: ${ 9 / 16 * 100}%;
    ${ coverImage && `
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0;
      width: 100% !important;
      height: 100% !important;
      @media (min-aspect-ratio: 16/9) {
        // tall
        min-width: 100vw;
        min-height: 56.25vw;
        margin-left: -50vw;
        margin-top: -28.125vw;
      }
      @media (max-aspect-ratio: 16/9) {
        // wide
        min-width: 177.77vh;
        min-height: 100vh;
        margin-left: -88.885vh;
        margin-top: -50vh;
      }
      min-width: 0;
      min-height: 0;
    ` }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  ` : `
  ` }
`

class Video extends Component {
  constructor(props) {
    super(props)
    this.state = { playing: false }
  }

  openVideo = () => {
    this.setState({ playing: true })
  }

  closeVideo = () => {
    this.setState({ playing: false })
  }

  render() {
    const { coverImage, url, loop, muted } = this.props
    const { playing } = this.state

    if (!url) {
      return false
    }

    return (
      <Wrapper hasCoverImage={coverImage} coverImage={coverImage}>
        {coverImage && !playing ? (
          <CoverImageWrap onClick={this.openVideo}>
            <CoverImage
              objectFit='cover'
              image={{
                fluid: {
                  aspectRatio: coverImage.fields.file.details.image.width / coverImage.fields.file.details.image.height,
                  src: coverImage.fields.file.url
                }
              }}
            />
            <PlayButton>
              <Button shape="circle">
                <MdPlayArrow size="36" onClick={this.openVideo} />
              </Button>
            </PlayButton>
          </CoverImageWrap>
        ) : ''}
        <Transition
          in={playing || !coverImage}
          timeout={{
            appear: 500,
            enter: 0,
            exit: 500
          }}
          mountOnEnter
          unmountOnExit
          appear
        >
          {transitionStatus => (
            <VideoWrapper transitionStatus={transitionStatus} hasCoverImage={coverImage} coverImage={coverImage}>
              {!coverImage && !playing && !url.includes("youtube") ? (
                <PlayButtonWrapper>
                  <PlayButton>
                    <Button shape="circle">
                      <MdPlayArrow size="36" onClick={this.openVideo} />
                    </Button>
                  </PlayButton>
                </PlayButtonWrapper>
              ): ''}
              <StyledVideo
                coverImage={coverImage}
                url={url}
                playing={playing}
                loop={loop}
                muted={muted}
                config={{
                  youtube: {
                    preload: true,
                    playerVars: {
                      color: 'white',
                      controls: 1,
                      disablekb: 1,
                      modestbranding: 1
                    }
                  }
                }}
                onClick={this.closeVideo}
              />
            </VideoWrapper>
          )}
        </Transition>
      </Wrapper>
    )
  }
}

Video.defaultProps = {
  playing: false,
  loop: false,
  muted: false
}

export default Video
