/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from '@emotion/styled';
import Link from 'src/components/Link'
import MobileHide from 'src/shared/MobileHide'
import MobileShow from 'src/shared/MobileShow'
import { withDrawerContext, TABS } from 'src/state/DrawerState';
import { withShopifyContext } from 'src/state/ShopifyState';
import MaterialIcon from 'src/components/MaterialIcon'
import Button from 'src/components/Button';

import { colors, animations, mq, util, typography } from 'src/styles'
import Grid from 'src/components/Grid';
import ConditionalRender from 'src/components/ConditionalRender'
import MailchimpSignup from 'src/components/MailchimpSignup'
import Cart from 'src/components/Cart'
import gridSettings from '../../styles/gridSettings';

const { timeout } = animations;

const ShadedWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  transition: opacity ${timeout / 2}ms cubic-bezier(0.4, 0, 0.6, 1);
  ${({ isOpen }) => isOpen
    ? `opacity: 1;`
    : ` opacity: 0;
        pointer-events: none;`
  }
`

const GridStyled = styled(Grid)`
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  transition: transform ${timeout / 2}ms cubic-bezier(0.4, 0, 0.6, 1);
  ${({ isOpen }) => isOpen
    ? `transform: none;`
    : `
    ${mq.largeAndUp} {
      transform: translate3d(120vw,0,0);
    }
        pointer-events: none;`
  }
`;


const InnerWrapper = styled.div`
  background: ${colors.white};
  height: 100%;
  color: ${colors.black};
`;

const Container = styled.div`
	${typography.bodyMedium}
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
	z-index: 2;
  ${mq.mediumAndBelow} {
    /* 
      icons have some padding to push them toward middle of screen
      this is used to match, so that text looks aligned
      particularly on the left hand side
    */
    padding-left: 6px; 
    padding-right: 6px;
  }
  ${ util.responsiveStyles('padding-top', 30, 20, 18, 18)}
  ${ util.responsiveStyles('padding-bottom', 30, 20, 18, 18)}
`

const HeaderContainer = styled(Container)`
  /* font-weight: 600; */
  ${typography.h5}
  ${mq.mediumAndBelow} {
    text-align: right;
  }
`;
const ContentContainer = styled(Container)`
`;
const FooterContainer = styled(Container)`
  align-self: flex-end;
`;

const Clickout = styled(MobileHide)`
  background: transparent;
  color: transparent;
`;

const DrawerLink = styled(Link)`
  margin: 10px 0;
  ${typography.bodyMedium}
`

const Drawer = ({
  filter,
  drawerContext: {
    isOpen,
    tab,
    toggleDrawer,
  },
  shopifyContext: {
    cart,
  }
}) => {

  const numberOfItemsInCart = (cart && cart.lineItems && (cart.lineItems.length > 0))
    ? cart.lineItems.length
    : null
  const conditionalGridSettings = {
    small: '[28]',
    medium: tab === TABS.CART ? '[8] [20]' : '[12] [16]',
    large: tab === TABS.CART ? '[12] [16]' : '[16] [12]',
    extraLarge: tab === TABS.CART ? '[18] [10]' : '[20] [8]'
  }
  const conditionalInnerGridSettings = {
    small: '1 [12] 1',
    medium: tab === TABS.CART ? '1 [18] 1' : '1 [14] 1',
    large: tab === TABS.CART ? '1 [14] 1' : '1 [10] 1',
    extraLarge: tab === TABS.CART ? '1 [8] 1' : '1 [6] 1'
  }
  return (
    <ShadedWrapper isOpen={isOpen}>
      <div>
        <GridStyled
          isOpen={isOpen}
          small={conditionalGridSettings.small}
          medium={conditionalGridSettings.medium}
          large={conditionalGridSettings.large}
          extraLarge={conditionalGridSettings.extraLarge}
          colGap="0"
          rowGap="0"
        >
          <Clickout onClick={toggleDrawer} />
          <InnerWrapper>
            <Grid
              isOpen
              small={conditionalInnerGridSettings.small}
              medium={conditionalInnerGridSettings.medium}
              large={conditionalInnerGridSettings.large}
              extraLarge={conditionalInnerGridSettings.extraLarge}
              style={{ height: '100%', alignContent: 'space-between' }}
              colGap="0"
              rowGap="0"
            >
              <ConditionalRender condition={tab === TABS.NAVIGATION}>
                <div>
                  <HeaderContainer />
                  <ContentContainer>
                    <MobileShow>
                      <div>
                        <DrawerLink type="textLink" to="/">Home</DrawerLink>
                      </div>
                      <div>
                        <DrawerLink type="textLink" to="/contact">Contact</DrawerLink>
                      </div>
                    </MobileShow>
                    {/* <div>
                      <DrawerLink type="textLink" to="/privacy">Privacy Policy</DrawerLink>
                    </div>
                    <div>
                      <DrawerLink type="textLink" to="/terms">Terms of Service</DrawerLink>
                    </div> */}
                  </ContentContainer>
                </div>
                <FooterContainer />
              </ConditionalRender>

              <ConditionalRender condition={tab === TABS.SUBSCRIBE}>
                <div>
                  <HeaderContainer />
                  <ContentContainer>
                    <MailchimpSignup />
                  </ContentContainer>
                </div>
                <FooterContainer />
              </ConditionalRender>

              <ConditionalRender condition={tab === TABS.FILTERS}>
                <div>
                  <HeaderContainer />
                  <ContentContainer>
                    <div>
                      <DrawerLink active={!filter} type="textLink" to="/">All</DrawerLink>
                    </div>
                    <div>
                      <DrawerLink active={filter === 'photos'} type="textLink" to="/photos">Photos</DrawerLink>
                    </div>
                    <div>
                      <DrawerLink active={filter === 'videos'} type="textLink" to="/videos">Videos</DrawerLink>
                    </div>
                    <div>
                      <DrawerLink active={filter === 'audio'} type="textLink" to="/audio">Audio</DrawerLink>
                    </div>
                    {/* <div>
                      <DrawerLink active={filter === 'store'} type="textLink" to="/store">Store</DrawerLink>
                    </div> */}
                  </ContentContainer>
                </div>
                <FooterContainer />
              </ConditionalRender>
              <ConditionalRender condition={tab === TABS.CART}>
                <div>
                  <HeaderContainer>
                    {numberOfItemsInCart
                      ? `${numberOfItemsInCart} items`
                      : ''}
                  </HeaderContainer>
                  <ContentContainer>
                    <Cart />
                  </ContentContainer>
                </div>
                <FooterContainer>
                  <Button
                    disabled={!(cart && cart.webUrl && (cart.webUrl.length > 0) && cart.lineItems && (cart.lineItems.length > 0))}
                    external
                    to={cart && cart.webUrl}
                  >
                    Check Out
                    {' '}
                    <MaterialIcon>arrow_forward</MaterialIcon>
                  </Button>

                </FooterContainer>
              </ConditionalRender>
            </Grid>
          </InnerWrapper>
        </GridStyled>
      </div>
    </ShadedWrapper>
  )
}

export default withShopifyContext(withDrawerContext(Drawer));