/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { lighten, rgba } from 'polished'
import Select, { components } from 'react-select'
import * as mq from 'src/styles/mediaQueries'
import { colors, typography, animations } from 'src/styles'

import { MdKeyboardArrowDown } from 'react-icons/md'

const Wrapper = styled.div`
  /* width: ${({ longestStringN }) => longestStringN ? `max(calc(${longestStringN}ch + 35px), 150px)` : '150px'}; */
  width: 180px;
  ${mq.mediumAndBelow} {
    margin-left: 0;
    margin-right: 0;
    ${({ nOptions, oIndex }) => {
    if (nOptions === 2) {
      return `
        width: calc(50% + 2px);
        margin-${oIndex === 0 ? 'right' : 'left'}: -2px;
      `
    } if (nOptions === 3) {
      if (oIndex === 0) {
        return `
          width: calc((100%/3) + 2px);
          margin-right: -2px;
        `
      } if (oIndex === 1) {
        return `
          width: calc((100%/3) + 4px);
          margin-left: -2px;
          margin-right: -2px;
        `
      } if (oIndex === 2) {
        return `
          width: calc((100%/3) + 2px);
          margin-left: -2px;
        `
      } return ''
    } return `width: 100%; `
  }
  }}
`

const customStyles = {
  indicatorsContainer: () => ({
  }),
  valueContainer: () => ({
  }),
  option: (provided, state = {}) => ({
    cursor: 'pointer',
    backgroundColor: state.isSelected ? colors.black : colors.white,
    color: state.isSelected ? colors.white : colors.black,
    border: 'none',
    borderTop: '2px solid black',
    boxShadow: 'none',
    margin: 0,
    fontSize: 'inherit',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 15px',
  }),
  control: (provided, state = {}) => {
    return ({
      ...provided,
      backgroundColor: state.hasValue ? colors.black : colors.white,
      color: state.hasValue ? colors.white : colors.black,
      cursor: 'pointer',
      border: '2px solid black',
      borderColor: `${colors.black} !important`,
      borderRadius: 0,
      boxShadow: 'none',
      height: '50px',
      padding: '0 15px',
    })
  },
  singleValue: (provided, state = {}) => {
    const opacity = state.isDisabled ? 0.5 : 1
    return {
      ...provided,
      color: state.hasValue ? colors.white : colors.black,
      transition: 'opacity 300ms',
      opacity,
      boxShadow: 'none',

    }
  },
  menuList: (provided, state = {}) => ({
    ...provided,
  }),
  menu: (provided, state = {}) => ({
    ...provided,
    boxShadow: 'none',
    margin: '0px 0',
  })
}

const MdKeyboardArrowDownStyled = styled(MdKeyboardArrowDown)`
	height:24px;
	width: 24px;
	margin-bottom:-7px;
`
const DropdownIndicator = () => <div><MdKeyboardArrowDownStyled size={18} /></div>

const MenuListStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0;
  border: 2px solid black;
  border-top: none;
  padding: 0;
  margin-top: -2px;
  box-shadow: none;
  ${({ nOptions, oIndex }) => {
    if (nOptions === 2 && oIndex === 1) return 'right: 0;';
    if (nOptions === 3 && oIndex === 1) return 'left: calc(-100% + 6px);';
    if (nOptions === 3 && oIndex === 2) return 'right: 0;';
    return ''
  }}
  ${mq.mediumAndBelow} {
  ${({ nOptions, oIndex }) => {
    if (nOptions === 2) {
      return `min-width: calc(200% - 4px);`
    }
    if (nOptions === 3) {
      if (oIndex === 0) {
        return `min-width: calc(300% - 6px);`
      }
      if (oIndex === 1) {
        return `min-width: calc(300% - 12px);`
      } 
        return `min-width: calc(300% - 6px);`
      
    }
    return 'min-width: 100%;'
  }}
  position: absolute;
  }
`

const menuListCreator = ({ nOptions, oIndex }) => {
  return (props) => {
    return (
      <MenuListStyled
        {...props}
        nOptions={nOptions}
        oIndex={oIndex}
      >
        {props.children}
      </MenuListStyled>
    )
  };
}


const Dropdown = ({ option, nOptions, oIndex, onChange, value }) => {
  const { values } = option
  const MenuList = menuListCreator({ nOptions, oIndex });
  const longestStringN = values && values
    .map(x => x.value && x.value.length)
    .reduce((a, b) => {
      return Math.max(a, b, option.name && option.name.length);
    })
  return (
    <Wrapper oIndex={oIndex} nOptions={nOptions} style={{ zIndex: 3 }} longestStringN={longestStringN}>
      <Select
        isSearchable={false}
        // menuIsOpen={oIndex === 2}
        title={option.name}
        components={{ DropdownIndicator, MenuList }}
        value={value}
        isClearable={false}
        styles={customStyles}
        options={values.map((x) => ({ value: x.value, label: x.value }))}
        placeholder={option.name}
        onChange={onChange}
      />
    </Wrapper>
  )
}
export default Dropdown;