import React, { Component, Fragment } from 'react'
import { rgba } from 'polished'
import styled from '@emotion/styled/macro'
import Image from 'src/components/GatsbyImage'
import ReactAudioPlayer from 'react-audio-player'
import { colors, typography, animations, util, mq } from 'src/styles'
import Button from 'src/components/Button'
import { MdPlayArrow, MdPause } from 'react-icons/md'
import { GoUnmute, GoMute } from 'react-icons/go'

// These are best kept even numbers
const trackHeight = '5'
const handleSize = '5'

const AudioPlayerElement = styled(ReactAudioPlayer)`
	margin-top: 100px;
	display: none !important;
`

const PlayerControls = styled.div`
	display: flex;
  flex-direction: column;
	align-items: space-between;
  flex: 1;
  height: 100%;
  width: 100%;
`

const ScrubberWrap = styled.div`
  width: 100%;
  display: flex;
	align-items: center;
`

const Time = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	${ typography.bodySmall}
	font-weight: 600;
	padding-top: 2px;
	${ ({ alignment }) => alignment && `text-align: ${alignment};`}
	width: ${ ({ duration }) => duration >= 3600 ? `5em` : `3.25em`};
	${ ({ hideMd }) => hideMd ? `
		${ mq.mediumAndBelow} {
			display: none;
		}
	` : ``}
`

const ScrubberInput = styled.input`
	position: absolute;
  /* cursor: pointer; */
	width: calc(100% + 12px);
	z-index: 2;
	height: calc(${handleSize}px + ${trackHeight}px);
	top: calc(-${handleSize / 2}px - ${trackHeight / 2}px - 1px);
	left: calc(-${handleSize / 2}px - ${trackHeight / 2}px);
	appearance: none;
	background: transparent;

	:focus {
		box-shadow: none;
		outline: none;
	}

	:active {
		cursor: grabbing;
	}

	::-webkit-slider-thumb {
		appearance: none;
		cursor: grab;
		width: calc(${handleSize}px + ${trackHeight}px);
		height: calc(${handleSize}px + ${trackHeight}px);
		border-radius: 50%;
		background: transparent;
		&:active {
			cursor: grabbing;
		}
	}

	::-moz-range-thumb {
		appearance: none;
		cursor: grab;
		width: calc(${handleSize}px + ${trackHeight}px);
		height: calc(${handleSize}px + ${trackHeight}px);
		border-radius: 50%;
		background: ${ colors.textColor};
		&:active {
			cursor: grabbing;
		}
	}

`

const ScrubberTrack = styled.div`
	height: ${ trackHeight}px;
	width: 100%;
	background: ${ rgba(colors.textColor, .2)};
	transition: transform ${ animations.mediumSpeed};
`

const ScrubberProgress = styled.div`
	content: ''
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: ${trackHeight}px;
	width: ${ ({ currentTime, duration }) => `${(currentTime / duration * 100).toPrecision(4)}%`};
	background: ${ colors.mainColor};
`

const Scrubber = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background: transparent;
	flex-grow: 1;
	&:hover {
		${ ScrubberTrack} {
			/* transform: scaleY(3); */
		}
	}
`

const ImageWrap = styled(Image)`
	${util.responsiveStyles('min-width', 150, 150, 110, 90)};
  ${util.responsiveStyles('max-width', 150, 150, 110, 90)};
  ${util.responsiveStyles('min-height', 150, 150, 110, 90)};
  ${util.responsiveStyles('max-height', 150, 150, 110, 90)};
  margin-right: 20px;
	object-fit: cover;
  align-self: flex-start;
	img {
		object-fit: cover !important;
	}
`

const Row = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RowButtons = styled(Row)`
  ${mq.mediumAndBelow} {
    padding-left: 30px;
    padding-right: 30px;
    width: auto;
  }
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 5px;
  align-items: center;
`

const Title = styled.div`
  ${({ titleLength }) => (
    titleLength > 88
      ? typography.bodySmall
      : typography.body
  )}
  margin-top: -.3rem;
  text-align: left;
  padding-bottom: 15px;
`

const ButtonStyled = styled(Button)`
  &:hover {
    opacity: .6;
  }
  max-width: none;
  height: auto;
  max-height: none;
  padding: 0;
  overflow: hidden;
  ${mq.mediumAndBelow} {
    svg {
      height: 60px;
    }
  }
  ${mq.smallAndBelow} {
    svg {
    height: 50px;
    }
  }
`

class AudioPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      playing: false,
      currentTime: 0,
      duration: null,
      muted: false
    }
    this.Player = React.createRef()
  }

  playPause = (play = false) => {
    if (play) {
      this.Player.current.audioEl.play()
    } else {
      this.Player.current.audioEl.pause()
    }
    this.setState({ playing: play })
  }

  muteUnmute = () => {
    const { muted } = this.state
    this.setState({ muted: !muted })
  }

  componentDidMount = () => {
    this.Player.current.audioEl.addEventListener('loadeddata', e => {
      this.setState({
        loading: false,
        duration: e.target.duration
      })
    })

    this.Player.current.audioEl.addEventListener('timeupdate', e => {
      this.setState({
        currentTime: e.target.currentTime,
      })
    })
  }

  componentWillUnmount = () => {
    this.Player.current.audioEl.removeEventListener('timeupdate', () => { })
  }

  secondsTohhmmss = (totalSeconds, duration) => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60)
    let seconds = totalSeconds - (hours * 3600) - (minutes * 60)

    const hasHours = duration >= 3600 || totalSeconds >= 3600

    // round seconds
    seconds = Math.round(seconds * 100) / 100

    const result = `${(hasHours ? `${hours < 10 ? `0${hours}` : hours}:` : '') + (minutes < 10 ? `0${minutes}` : minutes)}:${seconds < 10 ? `0${seconds}` : seconds}`
    return result
  }

  handleChange = event => {
    this.Player.current.audioEl.currentTime = event.target.value
  }

  render() {
    const { file, coverImageForAudioFile, title } = this.props
    const { currentTime, duration, playing, muted } = this.state

    if (!file) {
      return false
    }
    const aspectRatio = coverImageForAudioFile
      ? coverImageForAudioFile.fields.file.details.image.width / coverImageForAudioFile.fields.file.details.image.height
      : 1
    const src = coverImageForAudioFile
      ? coverImageForAudioFile.fields.file.url
      : 'note.png'
    return (
      <>
        <PlayerControls>
          <Row>
            <ImageWrap
              image={{
                fluid: {
                  aspectRatio,
                  src
                }
              }}
            />
            <Title titleLength={(title && title.length) || 0}>
              {title}
            </Title>
          </Row>
          <Column>
            <ScrubberWrap>
              <Scrubber>
                <ScrubberInput
                  type="range"
                  min={0}
                  max={duration}
                  value={currentTime}
                  onChange={this.handleChange}
                />
                <ScrubberTrack>
                  <ScrubberProgress
                    currentTime={currentTime}
                    duration={duration}
                  />
                </ScrubberTrack>
              </Scrubber>
            </ScrubberWrap>
            <Row style={{ width: "100%", justifyContent: 'space-between' }}>
              <Time duration={duration} alignment="left">{this.secondsTohhmmss(Math.round(currentTime), duration)}</Time>
              <Time duration={duration} alignment="right">{this.secondsTohhmmss(Math.round(duration))}</Time>
            </Row>
          </Column>
          <RowButtons style={{ flex: 1, width: "100%", justifyContent: 'space-around' }}>
            {playing
              ? (
                <ButtonStyled onClick={() => this.playPause(false)} setTheme="transparent">
                  <MdPause color={colors.black} size={75} />
                </ButtonStyled>
              )
              : (
                <ButtonStyled onClick={() => this.playPause(true)} setTheme="transparent">
                  <MdPlayArrow color={colors.black} size={75} />
                </ButtonStyled>
              )}
          </RowButtons>
        </PlayerControls>

        <AudioPlayerElement
          ref={this.Player}
          controls
          muted={muted}
          src={file.source_url}
        />
      </>
    )
  }
}

export default AudioPlayer
