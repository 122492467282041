import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Route, Switch, withRouter } from 'react-router-dom';
import PageTransition from 'src/components/PageTransition'

import SplashPage from 'src/components/SplashPage'
import HomePage from 'src/components/HomePage';
import CategoryPage from 'src/components/CategoryPage';
import PostPage from 'src/components/PostPage';
import TextPage, { TEXT_PAGE_TYPES } from 'src/components/TextPage';

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
`

class PageContent extends Component {

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(" ╔╗╔╗╦ ╦ \n ║╦╠╦║║║ Designed & built by\n ║║║║║║║ https://gordilsandwillis.com\n ╚╝╚╝╚╩╝ ");
  }

  render() {
    const { location } = this.props
    return (
      <Wrapper>
        <PageTransition location={location}>
          <Switch location={location}>
            <Route exact path="/" render={(props) => (<SplashPage {...props} />)} />
            <Route exact path="/feed" render={(props) => (<HomePage {...props} />)} />
            <Route exact path="/contact" render={(props) => <TextPage type={TEXT_PAGE_TYPES.contact} {...props} />} />
            <Route exact path="/privacy" render={(props) => <TextPage type={TEXT_PAGE_TYPES.privacy} {...props} />} />
            <Route exact path="/terms" render={(props) => <TextPage type={TEXT_PAGE_TYPES.terms} {...props} />} />
            <Route exact path="/:type/" render={(props) => <CategoryPage {...props} />} />
            <Route exact path="/:type/:slug" render={(props) => <PostPage {...props} />} />
          </Switch>
        </PageTransition>
      </Wrapper>
    )
  }
}

export default withRouter(PageContent);
