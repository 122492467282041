import React, { Component } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Block from 'src/components/Block'
import InstagramEmbed from 'react-instagram-embed';

const StyledInstagramEmbed = styled(InstagramEmbed)`
	width: 100%;
	overflow: hidden;
	max-width: 800px;
	iframe {
		width: 100% !important;
		max-width: 800px !important;
		margin: 0 !important;
	}
`

const BlockInstagram = ({ index, item, nextTheme, prevTheme }) => {
	const { sharePath, instagramUrl } = item.fields

	console.log(item)

	return (
  <Block
    item={item}
    nextTheme={nextTheme}
    prevTheme={prevTheme}
    index={index}
    sharePath={`photos/${  sharePath}`}
		>
    <StyledInstagramEmbed
      url={instagramUrl}
      clientAccessToken={`${ process.env.REACT_APP_INSTAGRAM_APP_ID }|${ process.env.REACT_APP_INSTAGRAM_CLIENT_TOKEN }`}
      maxWidth={1000}
      hideCaption
    />
  </Block>
	)
}

export default BlockInstagram
