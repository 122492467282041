/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import Header from 'src/components/Header'
import ATF from 'src/components/ATF'
import ComponentRenderer from 'src/components/ComponentRenderer'
import ReactGA from 'react-ga'
import { withShopifyContext } from 'src/state/ShopifyState'
import { withContentfulContext } from 'src/state/ContentfulState'
import SEO from 'src/components/SEO'
import sortContent from 'src/util/sortContent'

class HomePage extends Component {

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    const {
      contentfulContext: {
        cSiteSettings,
        cEntries,
      },
      match,
      shopifyContext: {
        products = []
      }
    } = this.props

    const { params } = match;

    const splashImage = cSiteSettings && cSiteSettings.aboveTheFoldImage && `https:${cSiteSettings.aboveTheFoldImage.fields.file.url}`
    const mobileSplashImage = cSiteSettings && cSiteSettings.mobileAboveTheFoldImage
      ? `https:${cSiteSettings.mobileAboveTheFoldImage.fields.file.url}`
      : splashImage

    let filteredContent = [...cEntries, ...products]
    filteredContent = sortContent(filteredContent)

    if (cSiteSettings && cSiteSettings.pinnedPost) {
      filteredContent = filteredContent.filter(item => !(item.sys && item.sys.id.includes(cSiteSettings.pinnedPost.sys.id)))
      filteredContent = [cSiteSettings.pinnedPost, ...filteredContent]
    }

    console.log(filteredContent)

    return (
      <>
        <SEO />
        <div>
          <Header
            filter={false}
            hasAtf
            bgImage={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            bgImageSmall={{
              fluid: {
                aspectRatio: 2,
                src: mobileSplashImage
              }
            }}
          />
          <ATF
            fullHeight={splashImage}
            headline={cSiteSettings ? cSiteSettings.siteTitle : 'Love Reprise'}
            headlineSize="h1"
            location={params.type}
            small={{
              fluid: {
                aspectRatio: 2,
                src: mobileSplashImage
              }
            }}
            medium={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            large={{
              fluid: {
                aspectRatio: 2,
                src: splashImage
              }
            }}
            nextTheme="bgColor"
            filters
          />
          {filteredContent
            .filter((item) => {
              // Hide Instagram posts for now
              return item.sys ? item.sys.contentType.sys.id !== 'contentItemInstagramEmbed' : true
            })
            .map((item, index) => {
            const firstItem = index === 0
            const lastItem = filteredContent.length === index + 1
            if (!item) return ''
            return (
              <ComponentRenderer
                key={`${item.sys ? item.sys.id : item.id}_${index}`}
                item={item}
                index={index}
                firstItem={firstItem}
                lastItem={lastItem}
              />
            )
          })}
        </div>
      </>
    );
  }
}

export default withContentfulContext(withShopifyContext(HomePage));