import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import globalStyles from 'src/styles/globalStyles'
import PageContent from 'src/components/PageContent'
// import ShopifyProvider from 'src/contexts/ShopifyContext'
import DrawerState from 'src/state/DrawerState';
import ShopifyState from 'src/state/ShopifyState';
import ContentfulState from 'src/state/ContentfulState';

import './reset.css'

const PageWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Routes = () => {
  return (
    <div>
      <Global styles={css`${globalStyles}`} />
      <ContentfulState>
        <DrawerState>
          <ShopifyState>
            <PageWrapper>
              <Router>
                <PageContent />
              </Router>
            </PageWrapper>
          </ShopifyState>
        </DrawerState>
      </ContentfulState>
    </div>
  )
}

export default Routes;