import React, { useState } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Block from 'src/components/Block'
import Image from 'src/components/GatsbyImage'
import Button from 'src/components/Button'
import Slideshow from 'src/components/Slideshow'
import { withShopifyContext } from 'src/state/ShopifyState'
import Dropdown from 'src/components/Dropdown'
import * as mq from 'src/styles/mediaQueries'

const BuyButton = styled(Button)`
  width: 180px;
  ${mq.mediumAndBelow} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

const BlockProduct = ({
  index,
  item,
  nextTheme,
  prevTheme,
  shopifyContext: {
    checkoutId,
    addLineItemToCart,
  }
}) => {
  const [variant, setVariant] = useState(null);
  const [userSelectedOptionsDict, setUserSelectedOptions] = useState({});
  const [images, setImages] = useState(item.images);
  const { handle } = item
  const { price, priceV2: { currencyCode } } = (variant || (item.variants && item.variants[0]) || item)
  console.log(item)
  const handleSetFilters = (obj) => {
    const newUserSelectedOptions = { ...userSelectedOptionsDict, ...obj }
    if (Object.keys(newUserSelectedOptions).length > 0) {
      // client-side filter for correct variant images if not all options selected
      const newImagesDict = item.variants.reduce((acc, v) => {
        const matches = Object.keys(newUserSelectedOptions).filter((filterName) => {
          const filterValue = newUserSelectedOptions[filterName]
          const filter = v.selectedOptions.find(({ name }) => name === filterName);
          return (filter.value === filterValue)
        })
        if (matches && (matches.length === Object.keys(newUserSelectedOptions).length)) {
          if (matches.length === item.options.length) {
            setVariant(v)
          }
          if (v.image && v.image.src) {
            // dictionary prevents duplicate sources rendering in slideshow
            acc[v.image.src] = v.image
          }
        }
        return acc;
      }, {})
      setUserSelectedOptions(newUserSelectedOptions)
      setImages(Object.values(newImagesDict))
    }
  }
  if (item.variants.length === 1) {
    setVariant(item.variants[0]);
  }
  // collect possible options, marking by availiability
  let { options } = item;
  const nUserSelectedOptions = Object.keys(userSelectedOptionsDict).length
  if (nUserSelectedOptions > 0 && nUserSelectedOptions !== item.options.length) {
    // if user has selected options but has not yet selected a variant
    const optionsDict = item.variants.reduce((acc, variantX) => {
      // for each variant
      let available;
      // if product isnt available by default, mark it unavailable
      if (item.available === false) available = false
      else {
        // else check that all values in userSelectedOptionsDict have matching value in selectedOptions
        const matchingOptionsFound = Object.keys(userSelectedOptionsDict)
          .filter(userSelectedOptionKey => variantX.selectedOptions
            .find(selectedOption => selectedOption.name === userSelectedOptionKey.name))

        // if # of matching options found is the same as # of user selected options, product is available
        available = matchingOptionsFound.length === nUserSelectedOptions
      }
      
      // now add to options accumulator, first checking if it exists already




      // if (variantX.selectedOptions) {
      //   variantX.selectedOptions.forEach(selectedOption => {
      //     // for each of the variant's selectedOptions

      //     // if userSelectedOptionsDict has a value for the key of selectedOption's name 
      //     // (which means user has already chosen a value for this option from dropdown)
      //     // then 
      //     // add selectedOption to acc dictionary using name as key
      //   })
      // }
      // 

    }, {})
    options = Object.values(optionsDict);
  }
  const priceString = currencyCode === 'USD' ? `$${price}` : `${price} ${currencyCode}`
  return (
    <Block
      item={item}
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      index={index}
      sharePath={`store/${handle}`}
      priceString={priceString}
      dropdown={
        options && options.map((option, oIndex) => (
          <Dropdown
            nOptions={options.length}
            oIndex={oIndex}
            key={option.id}
            option={option}
            onChange={(x) => handleSetFilters({ [option.name]: x.value })}
          >
            {option.name}
          </Dropdown>
        ))
      }
      extraButtons={(
        <BuyButton
          onClick={() => variant && addLineItemToCart({
            checkoutId,
            variantId: variant.id,
          })}
        >
          Buy
        </BuyButton>
      )}
    >
      <Slideshow
        items={(variant && variant.images) || images}
      />
    </Block>
  )
}

export default withShopifyContext(BlockProduct);
