import React from 'react'

import Block from 'src/components/Block'
import Video from 'src/components/Video'

const BlockVideo = ({ index, item, nextTheme, prevTheme }) => {
	const { sharePath, posterImage, vimeoEmbed, youtubeEmbed, file } = item.fields
	let videoUrl = ''

	if (file) {
		videoUrl = file.fields.file.url
	} else if (youtubeEmbed) {
		videoUrl = youtubeEmbed
	} else if (vimeoEmbed) {
		videoUrl = vimeoEmbed
	}

	return (
  <Block
    item={item}
    nextTheme={nextTheme}
    prevTheme={prevTheme}
    index={index}
    sharePath={`videos/${  sharePath}`}
		>
    <Video
      url={videoUrl}
      coverImage={posterImage}
      playing={!!file}
      loop={!!file}
      muted={!!file}
    />
  </Block>
	)
}

export default BlockVideo
