import { lighten, darken, rgba } from 'polished'

export const black = '#000'
export const white = '#fff'

// Site Specific Colors
export const yellow = '#EBCF55'
export const red = '#E15D54'
export const green = '#00C771'

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = white
export const mainColor = black
export const alert = red
export const notify = yellow
export const success = green
export const textColor = black
export const lightTextColor = rgba(textColor, 0.3)
export const lightGrey = '#f2f2f2'
export const darkGrey = '#6d7278'
export const hrColor = rgba(textColor, 0.1)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)

// Themes
export const themes = {
	default: {
		color: bgColor,
		background: mainColor,
		hoverColor: bgColor,
		hoverBackground: lighten(0.14, mainColor)
	},
	mainColor: {
		color: bgColor,
		background: mainColor,
		hoverColor: bgColor,
		hoverBackground: lighten(0.07, mainColor)
	},
	textColor: {
		color: bgColor,
		background: textColor,
		hoverColor: bgColor,
		hoverBackground: textColor
	},
	hollow: {
		color: textColor,
		background: transparent,
		hoverColor: bgColor,
		hoverBackground: textColor,
		borderColor: textColor
	},
	transparent: {
		color: lightTextColor,
		background: transparent,
		hoverColor: textColor,
		hoverBackground: transparent,
		borderColor: transparent
	},
	bgColor: {
		color: textColor,
		background: bgColor,
		hoverColor: textColor,
		hoverBackground: darken(0.07, bgColor),
		borderColor: bgColor
	}
}