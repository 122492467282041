/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Block from 'src/components/Block'
import Image from 'src/components/GatsbyImage'
import Slideshow from 'src/components/Slideshow'
import Ternary from 'src/components/Ternary'

const ImageWrap = styled(Image)`
	max-height: 80vh;
	object-fit: contain;
	img {
		object-fit: contain !important;
	}
`

const BlockImage = ({ index, item, nextTheme, prevTheme }) => {
  const { title, description, sharePath, clickthroughLink } = item.fields
  const itemType = item.sys.contentType.sys.id
  const itemId = item.sys.id
  const { images } = item.fields

  return (
    <Block
      item={item}
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      index={index}
      sharePath={`photos/${sharePath}`}
    >
      {images.length === 1 ? (
        <Ternary
          condition={clickthroughLink}
          trueComponent={(x) => <a {...x} href={clickthroughLink} target="_blank" rel="noopener noreferrer" />}
          falseComponent={(x) => <div {...x} />}
        >
          <ImageWrap
            image={{
              fluid: {
                aspectRatio: images[0].fields.file.details.image.width / images[0].fields.file.details.image.height,
                src: images[0].fields.file.url
              }
            }}
          />
        </Ternary>
      ) : (
        <Slideshow
          items={images}
          clickthroughLink={clickthroughLink}
        />
        )}
    </Block>
  )
}

export default BlockImage
