const ConditionalRender = ({
	condition,
	...props
}) => {
	if (condition) {
		return props.children
	} 
		return null
	
}

export default ConditionalRender
